import HyperDiv from "components/ProjectNonSpecificComponents/Elements/HyperDiv/HyperDiv"
import React from "react"
import dude from "./../../Images/other/dude.png"
import video from "./../../Video/first.mp4"

const Video = ({}) => {
	return (
		<HyperDiv centered>
			<HyperDiv width="90vw" maxWidth="1200px" padding="0px 0px 50px 0px">
				<video preload="none" autoplay="autoplay" loop="loop"  muted width="100%" height="500px">
					<source src={video} type="video/mp4" />
					<source src={video} type="video/ogg" />
					Your browser does not support the video tag.
				</video>
				{/* <iframe
					
					src="https://www.youtube.com/embed/oI7x85sr2WQ?controls=0&autoplay=1&loop=1&mute=1&autohide=0&modestbranding=1&autopause=0"
					title="3D Concrete Competition"
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				/> */}
			</HyperDiv>
		</HyperDiv>
	)
}

export default Video
