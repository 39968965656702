import HyperDiv from "components/ProjectNonSpecificComponents/Elements/HyperDiv/HyperDiv"
import Whitespace from "components/ProjectNonSpecificComponents/Elements/Whitespace"
import useScreenType from "components/ProjectNonSpecificComponents/Hooks/useScreenType"
import React from "react"
import { HeaderParagraph } from "./InfoP"
import HeadingText from "./../../Images/headings/PRIZES.png"
import PrizesText from "./../../Images/other/prizeText.png"
import regulations from "./pdf"

interface Props {}

const Prizes = (props: Props) => {
	const { isMobile } = useScreenType()
	return (
		<HyperDiv centered margin="100px 0px 0px 0px" id="prizes-section">
			<HyperDiv
				width="1100px"
				maxWidth="90%"
				direction="column"
				style={{
					display: "flex",
					justifyContent: "flex-start",
					alignItems: "center",
				}}
			>
				<img src={HeadingText} className="heading-text" alt="heading_text" />
				<Whitespace x={isMobile ? "2" : "5"} />

				<img
					src={PrizesText}
					width="700px"
					height="auto"
					style={{ maxWidth: "90%" }}
					alt="prizes_text"
				/>
				<Whitespace x={isMobile ? "3" : "10"} />

				<HyperDiv direction={isMobile ? "column" : "row"} spacedBetween={!isMobile} width="100%">
					<div
						style={{
							minWidth: "50",
							paddingRight: isMobile ? "" : "2.5rem",
							paddingBottom: isMobile ? "2.5rem" : "",
						}}
					>
						<HeaderParagraph>
							Full event price pool is <b>5 000 - 10 000 EUR</b>*. All teams who pass first stage
							idea selection get up to 500 EUR per team in material costs and expert consultations
							for preparing the prototype 3D printed scale model and A1 poster print for the
							exhibition. For the 3 best final awarded ideas main prizes are up to 3000 EUR per team
							for first place, 2000 EUR for second place and 1000 EUR for third place.
						</HeaderParagraph>
					</div>

					<div style={{ minWidth: "50%", paddingLeft: isMobile ? "" : "2.5rem" }}>
						<HeaderParagraph>
							From the 3 final awarded winners jury selects 1-3 ideas that will be 3D printed in
							full-scale concrete and exhibited at UL campus in Riga, Latvia. Full regulations
							available{" "}
							<a target="_blank" rel="noreferrer" href={regulations}>
								<b>HERE</b>
							</a>
							. In order to coordinate fund transfer it is recommended that each team has one full
							time student from Latvia. However you can also register as an individual and find a
							team later. <br /> <br />
							<i>*Prizes are shown in full amount before tax.</i>
						</HeaderParagraph>
					</div>
				</HyperDiv>
			</HyperDiv>
		</HyperDiv>
	)
}

export default Prizes
