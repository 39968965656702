import HyperDiv from "components/ProjectNonSpecificComponents/Elements/HyperDiv/HyperDiv"
import Whitespace from "components/ProjectNonSpecificComponents/Elements/Whitespace"
import useScreenType from "components/ProjectNonSpecificComponents/Hooks/useScreenType"
import React from "react"
import HeadingText from "./../../Images/headings/LOCATION.png"
import MapBoxMap from "./MapBox.Map"

const Location = () => {
	const { isMobile } = useScreenType()
	return (
		<HyperDiv centered margin="100px 0px 0px 0px" id="location-section">
			<HyperDiv
				width="100vw"
				direction="column"
				style={{
					display: "flex",
					justifyContent: "flex-start",
					alignItems: "center",
				}}
			>
				<img src={HeadingText} alt="heading_text" className="heading-text" />
				<Whitespace x={isMobile ? "3" : "6"} />
				<MapBoxMap />
			</HyperDiv>
		</HyperDiv>
	)
}

export default Location
