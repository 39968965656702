import Whitespace from "components/ProjectNonSpecificComponents/Elements/Whitespace"
import { useStore } from "components/ProjectSpecificComponents/Contexts/GlobalStoreContext"
import { observer } from "mobx-react"
import React, { useEffect, useState } from "react"
import styled from "styled-components"

interface Props {
	/**
	 * yyyy-MM-dd hh-mm-ss
	 */
	date: string
}

const CountDownClock = observer(({ date }: Props) => {
	const store = useStore()
	const calculateTimeLeft = () => {
		const difference = +new Date(date) - +new Date()

		let timeLeft = {}
		if (difference > 0) {
			timeLeft = {
				days: Math.floor(difference / (1000 * 60 * 60 * 24)),
				hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				minutes: Math.floor((difference / 1000 / 60) % 60),
				seconds: Math.floor((difference / 1000) % 60),
			}
			store.setRegistrationOpen(true)
			return timeLeft
		}
		store.setRegistrationOpen(false)
		return undefined
	}

	const [timeLeft, setTimeLeft] = useState<any>(calculateTimeLeft())

	useEffect(() => {
		setTimeout(() => {
			setTimeLeft(calculateTimeLeft())
		}, 1000)
	})

	return timeLeft ? <TimerView timeLeft={timeLeft} /> : <></>
})

export default CountDownClock

export const TimerView = ({ timeLeft }: any) => {
	return (
		<div>
			<Whitespace x="1" />

			<TimeHeader>Registration closes in: </TimeHeader>
			<Whitespace x=".2" />

			<div>
				<TimeLabel>
					<b>{timeLeft.days}</b> days
				</TimeLabel>
				<TimeLabel>
					<b>{timeLeft.hours}</b> h
				</TimeLabel>
			</div>
			<Whitespace x=".1" />
			<div>
				<TimeLabel>
					<b>{timeLeft.minutes}</b> min
				</TimeLabel>
				<TimeLabel>
					<b>{timeLeft.seconds}</b> s
				</TimeLabel>
			</div>
		</div>
	)
}

const TimeHeader = styled.p`
	font-weight: 300;
`
const TimeLabel = styled.label`
	padding-right: 10px;
	padding-top: 5px;
	font-size: 20px;
	letter-spacing: 1px;
`
