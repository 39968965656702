import React, { forwardRef, useState, ComponentProps, useEffect } from "react"
import Input from "../Input"
import MultilineInput from "../MultilineInput"

interface Props extends TComponentProps {
	charLimit?: number
	multiline?: boolean
	wide?: boolean
}

export const FormInput = forwardRef<HTMLInputElement & HTMLTextAreaElement, Props>(
	({ value, charLimit, multiline, wide, onChange = () => {}, ...props }, ref) => {
		const [text, setText] = useState(value)

		useEffect(() => {
			if (typeof value != "string") return
			setTextWithConstraints(value)
		}, [value])

		const setTextWithConstraints = (text: string) => {
			if (charLimit && text.length > charLimit) {
				setText(text.substr(0, charLimit))
				return
			}

			setText(text)
		}

		if (multiline) {
			return (
				<MultilineInput
					{...props}
					value={text}
					wide={wide}
					onChange={(e) => {
						setTextWithConstraints(e.target.value)
						onChange(e)
					}}
					ref={ref}
				/>
			)
		}

		return (
			<Input
				{...props}
				value={text}
				wide={wide}
				onChange={(e) => {
					setTextWithConstraints(e.target.value)
					onChange(e)
				}}
				ref={ref}
			/>
		)
	}
)

type TComponentProps = ComponentProps<"input"> & ComponentProps<"textarea">
