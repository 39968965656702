import React, { forwardRef } from "react"
import concatWithSpaces from "../../Functions/concatWithSpaces"

/**
 * By default is a `display: block;` container, however,
 * if any flex prop is used, then it becomes a `display: flex;` container.
 *
 *
 * ## Extra functionality
 *
 * #### `hidden`
 * An easier way to hide the whole div.
 * Keeps it in the DOM.
 *
 * #### `hidesOverflownText`
 * #### `hidesOverflownTextAllowingWrap`
 *
 * #### `gridArea`
 * Shorthand for setting `gridArea` style property.
 *
 * #### `spaceBetweenElems`
 * You may provide the desired space between your elements,
 * after setting the `direction` prop as well.
 */
const HyperDiv = forwardRef<HTMLDivElement, Props>(
	(
		{
			width = "100vw",
			maxWidth = "100%",
			height = "auto",
			padding,
			margin,
			direction,
			centered = false,
			centeredX = false,
			centeredY = false,
			alignedTop = false,
			alignedBottom = false,
			alignedLeft = false,
			alignedRight = false,
			stretchedAcross = false,
			baselined = false,
			spacedBetween = false,
			spacedAround = false,
			spacedEvenly = false,
			wraps = false,
			order,
			grows = false,
			doesNotShrink = false,
			basis,
			flex,
			alignedSelfStart = false,
			alignedSelfEnd = false,
			alignedSelfCenter = false,
			stretchedSelf = false,
			baselinedSelf = false,
			hidden = false,
			hidesOverflownText = false,
			hidesOverflownTextAllowingWrap = false,
			gridArea,
			spaceBetweenElems,
			style,
			className,
			children,
			...props
		},
		ref
	) => {
		const isRow = direction == "row"
		const isColumn = direction == "column"

		let spacedChildren: React.ReactNode[] | undefined
		if (spaceBetweenElems != null && direction && Array.isArray(children)) {
			spacedChildren = []
			for (let i = 0; i < children.length; ++i) {
				const elem = children[i]
				spacedChildren.push(elem)
				// If not last child element, append space
				if (i != children.length - 1) {
					spacedChildren.push(
						<div
							style={
								direction == "row"
									? {
											width: spaceBetweenElems,
									  }
									: {
											height: spaceBetweenElems,
									  }
							}
						/>
					)
				}
			}
		}

		return (
			<div
				{...props}
				className={concatWithSpaces(
					isRow ? "flex-row" : "",
					isColumn ? "flex-col" : "",
					centered ? "center-x-y" : "",
					centeredX ? "center-x" : "",
					centeredY ? "center-y" : "",
					alignedTop ? "flex-start-y" : "",
					alignedBottom ? "flex-end-y" : "",
					alignedLeft ? "flex-start-x" : "",
					alignedRight ? "flex-end-x" : "",
					stretchedAcross ? "flex-stretch" : "",
					baselined ? "flex-baseline" : "",
					spacedBetween ? "flex-space-between" : "",
					spacedAround ? "flex-space-around" : "",
					spacedEvenly ? "flex-space-evenly" : "",
					wraps ? "flex-allow-wrap" : "",
					grows ? "flex-grow-1" : "",
					doesNotShrink ? "flex-shrink-0" : "",
					alignedSelfStart ? "flex-align-self-start" : "",
					alignedSelfEnd ? "flex-align-self-end" : "",
					alignedSelfCenter ? "flex-align-self-center" : "",
					stretchedSelf ? "flex-align-self-stretch" : "",
					baselinedSelf ? "flex-align-self-baseline" : "",
					hidden ? "abs-hidden" : "",
					hidesOverflownText ? "hide-overflown-text-nowrap" : "",
					hidesOverflownTextAllowingWrap ? "hide-overflown-text-wrap" : "",
					className
				)}
				style={{
					width,
					maxWidth,
					height,
					padding,
					margin,
					order,
					flexBasis: basis,
					flex,
					gridArea,
					...style,
				}}
				ref={ref}
				children={spacedChildren ?? children}
			/>
		)
	}
)

export default HyperDiv

export type TFlexBoxDirection = "row" | "column"

interface Props extends React.ComponentProps<"div"> {
	// Base
	width?: string | number
	maxWidth?: string | number
	height?: string | number
	padding?: string | number
	margin?: string | number
	// Flex props
	/**
	 * #### Alias for:
	 * ```css
	 * flex-direction: row | column;
	 * ```
	 */
	direction?: TFlexBoxDirection
	/**
	 * #### Alias for:
	 * ```css
	 * justify-content: center;
	 * align-items: center;
	 * ```
	 */
	centered?: boolean
	/**
	 * #### Alias for:
	 * ```css
	 * justify-content: center;
	 * ```
	 */
	centeredX?: boolean
	/**
	 * #### Alias for:
	 * ```css
	 * align-items: center;
	 * ```
	 */
	centeredY?: boolean
	/**
	 * #### Alias for:
	 * ```css
	 * align-items: flex-start;
	 * ```
	 */
	alignedTop?: boolean
	/**
	 * #### Alias for:
	 * ```css
	 * align-items: flex-end;
	 * ```
	 */
	alignedBottom?: boolean
	/**
	 * #### Alias for:
	 * ```css
	 * justify-content: flex-start;
	 * ```
	 */
	alignedLeft?: boolean
	/**
	 * #### Alias for:
	 * ```css
	 * justify-content: flex-end;
	 * ```
	 */
	alignedRight?: boolean
	/**
	 * #### Alias for:
	 * ```css
	 * align-items: stretch;
	 * ```
	 */
	stretchedAcross?: boolean
	/**
	 * #### Alias for:
	 * ```css
	 * align-items: baseline;
	 * ```
	 */
	baselined?: boolean
	/**
	 * #### Alias for:
	 * ```css
	 * justify-content: space-between;
	 * ```
	 */
	spacedBetween?: boolean
	/**
	 * #### Alias for:
	 * ```css
	 * justify-content: space-around;
	 * ```
	 */
	spacedAround?: boolean
	/**
	 * #### Alias for:
	 * ```css
	 * justify-content: space-evenly;
	 * ```
	 */
	spacedEvenly?: boolean
	/**
	 * Whether flex item's wrap or not.
	 * By default, they do not.
	 *
	 * #### Alias for:
	 * ```css
	 * flex-wrap: wrap;
	 * ```
	 */
	wraps?: boolean
	// Flex-box child props
	/**
	 * #### Alias for:
	 * ```css
	 * order: <number>;
	 * ```
	 */
	order?: number
	/**
	 * -  Based on boolean is set to '1' or '0'.
	 * -  By default, this value in browser is '0'.
	 *
	 * #### Alias for:
	 * ```css
	 * flex-grow: <number>;
	 * ```
	 */
	grows?: boolean
	/**
	 * -  Based on boolean is set to '0' or '1'.
	 * -  By default, this value in browser is '1'.
	 *
	 * #### Alias for:
	 * ```css
	 * flex-shrink: <number>;
	 * ```
	 */
	doesNotShrink?: boolean
	/**
	 * #### Alias for:
	 * ```css
	 * flex-basis: <css-value-and-unit>;
	 * ```
	 */
	basis?: string | number
	/**
	 * #### Variations
	 *
	 * `flex: none` — value 'none' case
	 *
	 * `flex: <'flex-grow'>` — One value syntax, variation 1
	 *
	 * `flex: <'flex-basis'>` — One value syntax, variation 2
	 *
	 * `flex: <'flex-grow'> <'flex-basis'>` — Two values syntax, variation 1
	 *
	 * `flex: <'flex-grow'> <'flex-shrink'>` — Two values syntax, variation 2
	 *
	 * `flex: <'flex-grow'> <'flex-shrink'> <'flex-basis'>` — Three values syntax
	 *
	 * `flex: inherit`
	 *
	 * #### Examples
	 *
	 * `flex: 0 auto` = `flex: 0 1 auto` — default values
	 *
	 * `flex: auto` = `flex: 1 1 auto`
	 *
	 * `flex: none` = `flex: 0 0 auto`
	 *
	 * `flex: <positive-number>` = `flex: <positive-number> 1 0%` — can set proportions this way
	 *
	 * @see https://css-tricks.com/almanac/properties/f/flex/ for detailed explanations
	 *
	 * #### Alias for:
	 * ```css
	 * flex: <css-value-and-unit>;
	 * ```
	 */
	flex?: number | string
	/*
	 * #### Alias for:
	 * ```css
	 * align-self: flex-start;
	 * ```
	 */
	alignedSelfStart?: boolean
	/*
	 * #### Alias for:
	 * ```css
	 * align-self: flex-end;
	 * ```
	 */
	alignedSelfEnd?: boolean
	/*
	 * #### Alias for:
	 * ```css
	 * align-self: flex-center;
	 * ```
	 */
	alignedSelfCenter?: boolean
	/*
	 * #### Alias for:
	 * ```css
	 * align-self: stretch;
	 * ```
	 */
	stretchedSelf?: boolean
	/*
	 * #### Alias for:
	 * ```css
	 * align-self: baseline;
	 * ```
	 */
	baselinedSelf?: boolean
	// Extra functionality
	/**
	 * Hides the container in such a way that does not interfere
	 * with the layout, but keeps it rendered in DOM, meaning
	 * that it can still be accessed,
	 * e.g. focused with `inputRef.current.focus()`.
	 */
	hidden?: boolean
	hidesOverflownText?: boolean
	hidesOverflownTextAllowingWrap?: boolean
	gridArea?: string
	/**
	 * Inserts a CSS value of width/height/padding
	 * (whatever you want to call it)
	 * between the child items.
	 *
	 * Prop `direction` must be provided as well,
	 * and there must be multiple children,
	 * in order for this to work.
	 */
	spaceBetweenElems?: string | number
}
