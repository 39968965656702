import React, { forwardRef } from "react"
import styled from "styled-components"
import concatWithSpaces from "components/ProjectNonSpecificComponents/Functions/concatWithSpaces"
import useScreenType from "components/ProjectNonSpecificComponents/Hooks/useScreenType"

interface Props extends React.ComponentProps<"button"> {
	color: "red" | "green" | "gray"
	wide?: boolean
	inverted?: boolean
	semiTransparent?: boolean
	borderless?: boolean
	isActive?: boolean
	isInactive?: boolean
	size?: TSize
}

const Button = forwardRef<HTMLButtonElement, Props>(
	(
		{
			wide = false,
			inverted = false,
			disabled = false,
			semiTransparent = false,
			borderless = false,
			isActive,
			isInactive,
			onClick,
			style,
			className,
			color,
			size = "M",
			...props
		},
		ref
	) => {
		return (
			<CustomButton
				size={size}
				{...props}
				className={concatWithSpaces(
					"btn",
					`btn-${color}`,
					"pointer",
					onClick && !disabled ? "pointer" : "",
					inverted ? "inverted" : "",
					wide ? "wide" : "",
					isActive ? "active" : "",
					isInactive ? "inactive" : "",
					className
				)}
				style={{
					...style,
				}}
				disabled={disabled}
				onClick={onClick}
				ref={ref}
			/>
		)
	}
)

export default Button

type TSize = "S" | "M" | "L"

const CustomButton = styled.button<{ size: TSize }>`
	/* SIZE RELEVANT */

	height: ${({ size }) => (size === "S" ? "25px" : size === "M" ? "42px" : "50px")} !important;
	width: ${({ size }) => (size === "S" ? "150px" : size === "M" ? "210px" : "250px")} !important;
	font-size: ${({ size }) => (size === "S" ? "20px" : size === "M" ? "26px" : "30px")} !important;
	font-weight: ${({ size }) => (size === "S" ? "400" : "600")} !important;
	min-height: ${({ size }) => (size === "S" ? "20px" : size === "M" ? "32px" : "40px")} !important;

	letter-spacing: 2px;
	outline: none;
	border: none;
	color: white;
	font: inherit;
	border-radius: 25px;
	margin: 0px;
	padding: 0px;
	cursor: pointer !important;

	border: none;
	outline: none;

	:hover {
		outline: 2px solid #2d2a36;
	}
`
