import HyperDiv from "components/ProjectNonSpecificComponents/Elements/HyperDiv/HyperDiv"
import Whitespace from "components/ProjectNonSpecificComponents/Elements/Whitespace"
import useScreenType from "components/ProjectNonSpecificComponents/Hooks/useScreenType"
import React from "react"
import HeadingText from "./../../Images/headings/PARTNERS.png"
import partners from "./../../Images/other/partners_1.png"

const Partners = () => {
	const { isMobile } = useScreenType()
	return (
		<HyperDiv centered margin="100px 0px 0px 0px" id="partners-section">
			<HyperDiv
				width="1100px"
				maxWidth="90%"
				direction="column"
				style={{
					display: "flex",
					justifyContent: "flex-start",
					alignItems: "center",
				}}
			>
				<img src={HeadingText} alt="heading_text" className="heading-text" />
				<Whitespace x={isMobile ? "3" : "10"} />
				<img src={partners} alt="partners" width="100%" height="auto" />
			</HyperDiv>
		</HyperDiv>
	)
}

export default Partners
