import "./styles/keyframes.css"
import React from "react"
import styled from "styled-components"

import Loader from "./Loader"
import HyperDiv from "../HyperDiv/HyperDiv"

interface Props {
	active?: boolean
}

const GlobalOverlayLoader = ({ active }: Props) => {
	if (!active) return <></>

	return (
		<OverlayBase>
			<HyperDiv centered direction="column" width="100%" height="100%">
				<Loader />
			</HyperDiv>
		</OverlayBase>
	)
}

export default GlobalOverlayLoader

const OverlayBase = styled.div`
	overflow-x: hidden;
	overflow-y: hidden;

	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	padding-top: 100px; /* Location of the box */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	background-color: rgb(0, 0, 0); /* Fallback color */
`
