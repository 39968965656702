import HyperDiv from "components/ProjectNonSpecificComponents/Elements/HyperDiv/HyperDiv"
import Whitespace from "components/ProjectNonSpecificComponents/Elements/Whitespace"
import React from "react"
import HeadingText from "./../../Images/headings/SCHEDULE.png"
const MobileSchedule = () => {
	return (
		<HyperDiv
			style={{
				position: "relative",
				display: "flex",
				alignItems: "center",
				justifyContent: "flex-start",
			}}
			id="schedule-section"
			className="btn-top-style"
			height="auto"
			direction="column"
		>
			<img src={HeadingText} className="heading-text" alt="heading_text" />
			<Whitespace x="5" />
			<HyperDiv padding="0px 20px">
				<p>
					<b>October - November</b> - introductionary optional online training events every Tuesday
					19:00 -{" "}
					<a href="https://www.facebook.com/c2days" rel="noreferrer" target="_blank">
						fb.me/c2days
					</a>
				</p>
				<Whitespace x="0.5" />
				<p>
					<b>November 16</b> - 4h Idea hackathon - selecting teams for further work
				</p>
				<Whitespace x="0.5" />
				<p>
					<b>December - March</b> - 3 month mentoring for selected teams
				</p>
			</HyperDiv>
		</HyperDiv>
	)
}

export default MobileSchedule
