import React, { createContext } from "react";
import { useLocalObservable } from "mobx-react";
import createStore from '../Stores/GlobalStore';

const StoreContext = createContext(createStore());

export const GlobalStoreContextProvider = ({ children }: any) => {
  const store = useLocalObservable(createStore);

  return (
    <StoreContext.Provider value={store}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => React.useContext(StoreContext);
