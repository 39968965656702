import HyperDiv from "components/ProjectNonSpecificComponents/Elements/HyperDiv/HyperDiv"
import Whitespace from "components/ProjectNonSpecificComponents/Elements/Whitespace"
import Button from "components/ProjectSpecificComponents/Elements/Button"
import scrollTo from "components/ProjectSpecificComponents/Functions/scrollTo"
import React from "react"
import { useHistory } from "react-router"
import HeaderButtons from "./HeaderButtons"
import Images from "./Images"
import InfoP from "./InfoP"
import Mentors from "./Mentors"
import NavButtons from "./NavButtons"
import Prizes from "./Prizes"
import Schedule from "./Schedule"
import Video from "./VideoLoop"
import useScreenType from "components/ProjectNonSpecificComponents/Hooks/useScreenType"
import MobileSchedule from "./MobileSchedule"
import bg from "./../../Images/other/bg.png"
import concreteLogo from "./../../Images/other/3dConcrete.png"
import Partners from "./Partners"
import Evaluation from "./Evaluation"
import Location from "./Location"
import ExampleImages from "./ExampleImages"
import { ERoutes } from "App"

const Landing = () => {
	const history = useHistory()
	const { isMobile, isDesktop, isTablet, isSmallTablet } = useScreenType()

	const onRegister = () => {
		scrollTo()
		history.push(ERoutes.register)
	}

	return (
		<HyperDiv
			style={{
				overflowY: "scroll",
				overflowX: "hidden",
				position: "absolute",
				backgroundImage: `url(${bg})`,
				backgroundSize: "100vw 1000px",
				backgroundRepeat: "no-repeat",
			}}
			height="fit-content"
			direction="column"
		>
			<NavButtons />

			<Whitespace x={isMobile ? "6" : "14"} />
			<HyperDiv centered={!isMobile}>
				<img
					alt="concrete_logo"
					src={concreteLogo}
					width="1200px"
					height="auto"
					style={{
						maxWidth: isDesktop || isTablet || isSmallTablet ? "100%" : "",
						contentVisibility: "auto",
					}}
				/>
			</HyperDiv>

			<Whitespace x={isMobile ? "2" : "4"} />
			<HeaderButtons />

			<Whitespace x="7" />
			<InfoP />

			<Whitespace x="5" />
			<Video />

			<Whitespace x={isMobile ? "" : "5"} />
			{isMobile ? <MobileSchedule /> : <Schedule />}

			<Whitespace x={isMobile ? "7" : "10"} />
			<ExampleImages />

			<Whitespace x={isMobile ? "" : "5"} />
			<Mentors />

			<Whitespace x={isMobile ? "" : "5"} />
			<Prizes />

			<Whitespace x={isMobile ? "" : "5"} />
			<Evaluation />
			<Location />
			<Whitespace x={isMobile ? "2" : "7"} />

			<HyperDiv centered>
				<Button color="red" onClick={onRegister}>
					register
				</Button>
			</HyperDiv>

			<Whitespace x={isMobile ? "3" : "7"} />

			<Images />

			<Whitespace x={isMobile ? "" : "5"} />
			<Partners />

			<Whitespace x="10" />
		</HyperDiv>
	)
}

export default Landing
