import React, { forwardRef } from "react"
import styled from "styled-components"

interface Props extends React.ComponentProps<"select"> {
	wide?: boolean
}

const DefaultSelect = forwardRef<HTMLSelectElement, Props>(
	({ wide = false, style, ...props }, ref) => {
		return (
			<CustomSelect
				{...props}
				style={{
					width: wide ? "100%" : 300,
					maxWidth: "100%",
					...style,
				}}
				ref={ref}
			/>
		)
	}
)

export default DefaultSelect

const CustomSelect = styled.select`
	height: 38px;
	padding: 0 10px;
	background-color: #161616 !important;
	background: #161616 !important;
	border-radius: 3px;
	border: 1px solid #292929;
	outline: 0ch !important;
	// Text
	color: white;
	font-family: inherit;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.6;
	text-transform: none;
	word-wrap: normal;

	// Animation
	transition-property: border-color;
`
