import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import useScreenType from 'components/ProjectNonSpecificComponents/Hooks/useScreenType';


mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

const Map = () => {
	const mapContainerRef = useRef(null);

	const [lat, setLat] = useState(56.936986)
	const [lng, setLng] = useState(24.096894)
	const [zoom, setZoom] = useState(14)

	// Initialize map when component mounts
	useEffect(() => {
		const map = new mapboxgl.Map({
			container: mapContainerRef.current,
			style: 'mapbox://styles/matisszemturis/ckvu15zqu2jwk14t7m6ipqho0',
			center: [lng, lat],
			zoom: zoom,
			doubleClickZoom: true
		});

		const el = document.createElement('div');
		el.className = 'marker';

		new mapboxgl.Marker(el)
			.setLngLat([lng, lat])
			.addTo(map);

		map.scrollZoom.disable();

		map.on('move', () => {
			setLng(map.getCenter().lng.toFixed(4));
			setLat(map.getCenter().lat.toFixed(4));
			setZoom(map.getZoom().toFixed(2));
		});

		// Clean up on unmount
		return () => map.remove();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const { isMobile } = useScreenType()
	
	return (
		<div className='map-container' ref={mapContainerRef} style={{ position: 'relative', width: "100vw", height: isMobile ? 400 : 600 }} />
	);
};

export default Map;