import React from "react"
import styled from "styled-components"

interface Props {
	color?: string
	bgColor?: string
}

const LoaderOverlay = ({ color = "white", bgColor = "black" }: Props) => {
	return <SpinningCircle color={color} bgColor={bgColor} />
}

export default LoaderOverlay

const SpinningCircle = styled.div<Props>`
	border: 5px solid ${(props) => props.bgColor};
	border-radius: 50%;
	border-top: 5px solid ${(props) => props.color};
	width: 40px;
	height: 40px;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;

	/* Safari */
	@-webkit-keyframes spin {
		0% {
			-webkit-transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`
