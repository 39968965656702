import HyperDiv from "components/ProjectNonSpecificComponents/Elements/HyperDiv/HyperDiv"
import Whitespace from "components/ProjectNonSpecificComponents/Elements/Whitespace"
import useScreenType from "components/ProjectNonSpecificComponents/Hooks/useScreenType"
import React from "react"
import { HeaderParagraph } from "./InfoP"
import HeadingText from "./../../Images/headings/EVALUATION.png"
import styled from "styled-components"

import bench from "./../../Images/criteria/bench.png"
import bike from "./../../Images/criteria/bike.png"
import box from "./../../Images/criteria/box.png"
import glasses from "./../../Images/criteria/eyeglasses.png"
import pipe from "./../../Images/criteria/smoking-pipe.png"
import splatter from "./../../Images/criteria/splatter.png"

const Evaluation = () => {
	const { isMobile } = useScreenType()
	return (
		<HyperDiv centered margin="70px 0px 0px 0px" id="criteria-section">
			<HyperDiv
				width="1100px"
				maxWidth="90%"
				direction="column"
				style={{
					display: "flex",
					justifyContent: "flex-start",
					alignItems: "center",
				}}
			>
				<img src={HeadingText} alt="heading_text" className="heading-text-big" />
				<Whitespace x={isMobile ? "2" : "5"} />

				<HyperDiv direction={isMobile ? "column" : "row"} spacedBetween={!isMobile} width="100%">
					<div
						style={{
							minWidth: "50",
							paddingRight: isMobile ? "" : "2.5rem",
							paddingBottom: isMobile ? "2.5rem" : "",
						}}
					>
						<HeaderParagraph>
							Your submitted works are evaluated in following criteria that involve design and
							originality of the proposal, maintenance and understanding of 3D concrete printing
							technology. In the first part of the competition - 4h idea hackathon - priority will
							be given to the originality and ambition of your idea. On final presentations emphasis
							will be shifted towards maintenance and fulfillment of technology requirements.
						</HeaderParagraph>
						<Whitespace x="1" />
						<HeaderParagraph>
							<b>Design and idea</b> - how original and unique is the proposed freestanding object,
							its implementation in surroundings and aesthetic qualities. This section is evaluated
							by an architect and representatives from University of Latvia and Riga Technical
							university.
						</HeaderParagraph>
					</div>

					<div style={{ minWidth: "50%", paddingLeft: isMobile ? "" : "2.5rem" }}>
						<HeaderParagraph>
							<b>3D printing technology</b> - Understanding of 3D printing technology, involved
							challenges, limitations and opportunities. This section will be evaluated by experts
							from Riga Techical university 3D concrete printing laboratory. Feel free to watch the
							introductionary lecture on{" "}
							<b>
								<a href="https://fb.watch/8X6x5alpfC/" target="_blank">
									facebook
								</a>
							</b>{" "}
							from head researcher Māris Šinka.
						</HeaderParagraph>
						<Whitespace x="1" />
						<HeaderParagraph>
							<b> Object Maintenance</b> - In order to have a feasable installation its use cases
							and maintenance requirements must be considered in several use scenarios and weather
							conditions. This section will be overseen by University of Latvia Department of
							Infrastructure head Marģers Počs.
						</HeaderParagraph>
					</div>
				</HyperDiv>

				<Whitespace x="6" />
				<Criteria>
					<HyperDiv direction="column" width={isMobile ? "100%" : "50%"} centered>
						<img src={bench} alt="bench" />
						<p>
							<b>Bench</b> - enhance the UL Campus territory with a unique outdoor seating solution
							for students and visitors. Consider the location, comfort requirements, possible use
							cases and other existing environmental factors and design cues present in the campus
							currently.
						</p>
					</HyperDiv>

					<HyperDiv direction="column" width={isMobile ? "100%" : "50%"} centered>
						<img src={bike} alt="bike" />
						<p>
							<b>Bicycle Stand</b> - improve accessiblity of the university campus by designing a
							custom bicycle stand that provides sufficient security, visual interest and has
							adequate surface treatment for interaction with bicycle frames. You can also consider
							combination of several materials or techniques, whilst ensuring that the main body of
							the object is from 3D concrete.
						</p>
					</HyperDiv>

					<HyperDiv direction="column" width={isMobile ? "100%" : "50%"} centered>
						<img src={glasses} alt="glasses" />
						<p>
							<b>Isaiah Berlin memmorial</b> - Design a small-scale monument for the famous
							philosopher, historian and professor at the University of Oxford, born in Riga. Isaiah
							Berlin was a defender of liberalism, tolerance, value pluralism and individual
							liberty. What form or installation could best honor his ideas and serve as an
							inspiration for people who study humanities and social sciences at UL.
						</p>
					</HyperDiv>

					<HyperDiv direction="column" width={isMobile ? "100%" : "50%"} centered>
						<img src={box} alt="box" />
						<p>
							<b>House of Letters Demo centre</b> - Create a proposal for a part of House of Letters
							information centre pavillion with area for information displays, scale model display
							for campus development plan and small meetings & pop-up event zone.
						</p>
					</HyperDiv>

					<HyperDiv direction="column" width={isMobile ? "100%" : "50%"} centered>
						<img src={pipe} alt="pipe" style={{ paddingTop: 20 }} />
						<p>
							<b>Contemplation area</b> - designated, wind protected area for recollection of
							thoughts and/or a smoking zone that is visually seperated from the public zone.
						</p>
					</HyperDiv>

					<HyperDiv direction="column" width={isMobile ? "100%" : "50%"} centered>
						<img src={splatter} alt="splatter" />
						<p>
							<b>Free form sculpture or installation</b> - surprise us with your ambition and
							propose a custom freestanding sculpture or art installation for UL campus.
						</p>
					</HyperDiv>
				</Criteria>
			</HyperDiv>
		</HyperDiv>
	)
}

export default Evaluation

const Criteria = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	align-items: flex-start;

	div {
		padding: 15px;
		margin: 50px 0px;
	}

	img {
		width: 100px;
		margin-bottom: 100px;
	}
`
