import HyperDiv from "components/ProjectNonSpecificComponents/Elements/HyperDiv/HyperDiv"
import Whitespace from "components/ProjectNonSpecificComponents/Elements/Whitespace"
import React from "react"
import styled from "styled-components"
import first from "./../../Images/list/new/first.png"
import second from "./../../Images/list/new/second.png"
import third from "./../../Images/list/new/third.png"
import fourth from "./../../Images/list/new/fourth.png"

import HeadingText from "./../../Images/headings/SCHEDULE.png"

const TEXT_GAP = "5"

const Schedule = () => {
	return (
		<HyperDiv centered id="schedule-section">
			<HyperDiv
				width="800px"
				maxWidth="90%"
				direction="column"
				style={{
					display: "flex",
					justifyContent: "flex-start",
					alignItems: "center",
				}}
			>
				<img src={HeadingText} className="heading-text" alt="heading_text" />

				<Whitespace x="10" />

				<HyperDiv direction="row" height="auto">
					{/* <Line /> */}
					<HyperDiv direction="column">
						<HyperDiv direction="row" centered>
							<NumberImg
								src={first}
								width="60px"
								height="60px"
								style={{ boxShadow: "-50px -100px 0 60px black" }}
							/>
							<ListText>
								Participate in weekly introductionary online events with topics on Virtual Reality,
								3D modeling, concrete printing technologies and clay sculpting at Creative Tuesdays{" "}
								<a href="https://www.facebook.com/c2days" rel="noreferrer" target="_blank">
									fb.me/c2days
								</a>
							</ListText>
						</HyperDiv>
						<Whitespace x={TEXT_GAP} />
						<HyperDiv direction="row" centered>
							<NumberImg src={second} width="60px" height="55px" />
							<ListText>
								4h idea competition online with 3 min proposal pitch. Awarded teams proceed to the
								next stage. Find out more on Facebook event
							</ListText>
						</HyperDiv>
						<Whitespace x={TEXT_GAP} />

						<HyperDiv direction="row" centered>
							<NumberImg src={third} width="60px" height="60px" />
							<ListText>
								Individual mentorship for 10 selected teams in further developing the idea and test
								printing a 3D scale model prototype. Submitting posters for final evaluation
							</ListText>
						</HyperDiv>
						<Whitespace x={TEXT_GAP} />

						<HyperDiv direction="row" centered>
							<NumberImg
								src={fourth}
								width="60px"
								height="60px"
								style={{ boxShadow: "-40px 50px 0px 30px black" }}
							/>
							<ListText>
								Awarding selected teams after the final evaluations. Starting production of large
								scale printed parts and assembly on the site
							</ListText>
						</HyperDiv>
					</HyperDiv>
				</HyperDiv>
			</HyperDiv>
		</HyperDiv>
	)
}

export default Schedule

const ListText = styled.p`
	padding-right: 30px;
	text-align: left;
`

const Line = styled.div`
	border-right: 2px solid white;
	height: auto;
	width: 2px;
	margin-left: 30px;
`
const NumberImg = styled.img`
	position: relative;
	left: -31.5px;
`
