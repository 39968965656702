export default function createStore() {
	return {
		isRegistrationOpen: true,
		setRegistrationOpen(bool: boolean) {
			this.isRegistrationOpen = bool
		},

		infoModalConfig: infoModalConfig,
		/**
		 * By default, shows the info message
		 * (`show` property `true`, if `undefined`).
		 */
		setInfoModalConfig({
			titleKey,
			descriptionKey,
			show,
			showCancelBtn,
			type,
			showDuration,
			cancelTextKey,
			proceedTextKey,
			onCancel,
			onProceed,
		}: any & {
			// Mandatory properties to provide for this setter function
			titleKey: string
			type: any
		}) {
			this.infoModalConfig = {
				titleKey,
				descriptionKey: descriptionKey ?? defaultInfoModalConfig.descriptionKey,
				show: show ?? true, // !Attention: The only property that does not utilize default config
				showCancelBtn: showCancelBtn ?? defaultInfoModalConfig.showCancelBtn,
				type,
				showDuration: showDuration ?? defaultInfoModalConfig.showDuration,
				cancelTextKey: cancelTextKey ?? defaultInfoModalConfig.cancelTextKey,
				proceedTextKey: proceedTextKey ?? defaultInfoModalConfig.proceedTextKey,
				onCancel: onCancel ?? defaultInfoModalConfig.onCancel,
				onProceed: onProceed ?? defaultInfoModalConfig.onProceed,
			}
		},
		triggerGoToFirstPage: false,
		setTriggerGoToFirstPage() {
			return (this.triggerGoToFirstPage = !this.triggerGoToFirstPage)
		},
		/**
		 * Resets AppInfoModal config to its default state
		 */
		resetInfoModalConfig() {
			return (this.infoModalConfig = defaultInfoModalConfig)
		},

		hoveredCellOffsetLeft: hoveredCellOffsetLeft,
		setHoveredCellOffsetLeft(val: number | null) {
			this.hoveredCellOffsetLeft = val
		},
		isTableCellInputFocused: false,
		setIsTableCellInputFocused(bool: boolean) {
			this.isTableCellInputFocused = bool
		},

		/**
		 * Overlay
		 */
		isLoaderOverlayOpen: false,
		setLoaderOverlayOpen(bool: boolean) {
			return (this.isLoaderOverlayOpen = bool)
		},

		triggerFetchData: false,
		setTriggerFetchData() {
			this.triggerFetchData = !this.triggerFetchData
		},
	}
}

export type TKayakingRoute = {
	id: string
	lengthFrom: number | null
	lengthTo: number
	label: string | undefined
	imgUrl: string
	difficulty: string
	startingPointKey: string
	desc: string
	routeKML: string
	km: number | null
	cardImgUrl: string
}

/**
 * Default objects
 */ /** */
const defaultInfoModalConfig: any = {
	titleKey: "",
	descriptionKey: "",
	show: false,
	showCancelBtn: false,
	type: "info",
	showDuration: -1,
	cancelTextKey: "infoModal.no",
	proceedTextKey: "infoModal.ok",
	onCancel: () => {},
	onProceed: () => {},
}

const infoModalConfig: any = defaultInfoModalConfig
const hoveredCellOffsetLeft: number | null = null

export type TFieldChange = {
	[fieldName: string]: {
		timeMS: number
		fieldName: string
	}
}

export type TUserUISection = "newRequest" | "myRequests"
