import HyperDiv from "components/ProjectNonSpecificComponents/Elements/HyperDiv/HyperDiv"
import React from "react"

import indrikis from "./../../Images/people/IndrikisMuiznieks.png"
import ugis from "./../../Images/people/UgisBratuskins.png"
import maris from "./../../Images/people/MarisSinka.png"
import margers from "./../../Images/people/MargersPocs.png"
import laila from "./../../Images/people/LailaKundzina.png"
import voldemars from "./../../Images/people/Voldermars.png"
import matijs from "./../../Images/people/Matijs.png"
import Fabian from "./../../Images/people/Fabian.png"
import Whitespace from "components/ProjectNonSpecificComponents/Elements/Whitespace"
import styled from "styled-components"
import useScreenType from "components/ProjectNonSpecificComponents/Hooks/useScreenType"

import Krists from "./../../Images/people/Krists.png"
import Ella from "./../../Images/people/Ella.png"
import KristofersCelms from "./../../Images/people/KristofersCelms.png"
import Juris from "./../../Images/people/Juris.png"

import Elina from "./../../Images/people/Elina.png"
import Ervins from "./../../Images/people/Ervins.png"
import Atis from "./../../Images/people/AtisK.png"
import Janis from "./../../Images/people/JanisPloksNew.png"
import AlexGerzatovics from "./../../Images/people/AleksandrsGerzatovics.png"

import HeadingText from "./../../Images/headings/JURYMENTORS.png"
interface Props {}

const Mentors = (props: Props) => {
	const { isMobile, isDesktop, isSmallTablet, isTablet } = useScreenType()
	const dynamicWidth = isDesktop ? "25%" : isTablet ? "33%" : "50%"

	return (
		<HyperDiv centered margin="100px 0px 0px 0px" id="jury-section">
			<HyperDiv
				width="1100px"
				maxWidth="90%"
				direction="column"
				style={{
					display: "flex",
					justifyContent: "flex-start",
					alignItems: "center",
				}}
			>
				<img src={HeadingText} className="heading-text" />

				<Whitespace x={isMobile ? "3" : "10"} />
				<People>
					<HyperDiv width={dynamicWidth} direction="column" centered>
						<img src={indrikis} />
						<Whitespace x="1" />
						<HyperDiv
							style={{ alignItems: "start" }}
							centered={isMobile || isSmallTablet}
							direction="column"
						>
							<b>Prof. Indriķis Muižnieks </b>
							<p>University of Latvia rector </p>
						</HyperDiv>
					</HyperDiv>

					<HyperDiv width={dynamicWidth} direction="column" centered>
						<img src={ugis} />
						<Whitespace x="1" />
						<HyperDiv
							style={{ alignItems: "start" }}
							centered={isMobile || isSmallTablet}
							direction="column"
						>
							<b>Prof. Uģis Bratuškins </b>
							<p>Dean of Faculty of Architecture at Riga Technical university </p>
						</HyperDiv>
					</HyperDiv>

					<HyperDiv width={dynamicWidth} direction="column" centered>
						<img src={maris} />
						<Whitespace x="1" />
						<HyperDiv
							style={{ alignItems: "start" }}
							centered={isMobile || isSmallTablet}
							direction="column"
						>
							<b>Māris Šinka </b>
							<p>Head researcher at RTU 3D concrete printing laboratory </p>
						</HyperDiv>
					</HyperDiv>

					<HyperDiv width={dynamicWidth} direction="column" centered>
						<img src={Janis} />
						<Whitespace x="1" />
						<HyperDiv
							style={{ alignItems: "start" }}
							centered={isMobile || isSmallTablet}
							direction="column"
						>
							<b>Jānis Ploks </b>
							<p>University of Latvia </p>
							<p>“House of Letters” project director</p>
						</HyperDiv>
					</HyperDiv>

					<HyperDiv width={dynamicWidth} direction="column" centered>
						<img src={laila} />
						<Whitespace x="1" />
						<HyperDiv
							style={{ alignItems: "start" }}
							centered={isMobile || isSmallTablet}
							direction="column"
						>
							<b>Laila Kundziņa </b>
							<p>CEO, Board Member UL Foundation </p>
						</HyperDiv>
					</HyperDiv>

					<HyperDiv width={dynamicWidth} direction="column" centered>
						<img src={voldemars} />
						<Whitespace x="1" />
						<HyperDiv
							style={{ alignItems: "start" }}
							centered={isMobile || isSmallTablet}
							direction="column"
						>
							<b>Voldemārs Johansons </b>
							<p>Visionary Culture Foundation, Artist</p>
						</HyperDiv>
					</HyperDiv>

					<HyperDiv width={dynamicWidth} direction="column" centered>
						<img src={matijs} />
						<Whitespace x="1" />
						<HyperDiv
							style={{ alignItems: "start" }}
							centered={isMobile || isSmallTablet}
							direction="column"
						>
							<b>Matijs Babris </b>
							<p>Architect, researcher Riga Technical university</p>
						</HyperDiv>
					</HyperDiv>

					<HyperDiv width={dynamicWidth} direction="column" centered>
						<img src={AlexGerzatovics} />
						<Whitespace x="1" />
						<HyperDiv
							style={{ alignItems: "start" }}
							centered={isMobile || isSmallTablet}
							direction="column"
						>
							<b>Aleksandrs Gerzatovics</b>
							<p>Infrastructure Department University of Latvia</p>
						</HyperDiv>
					</HyperDiv>
					<HyperDiv width={dynamicWidth} direction="column" centered>
						<img src={Krists} />
						<Whitespace x="1" />
						<HyperDiv
							style={{ alignItems: "start" }}
							centered={isMobile || isSmallTablet}
							direction="column"
						>
							<b>Krists Ziemelis</b>
							<p>Industrial Phd Student, 3D Concrete printing laboratory</p>
						</HyperDiv>
					</HyperDiv>

					<HyperDiv width={dynamicWidth} direction="column" centered>
						<img src={Ella} />
						<Whitespace x="1" />
						<HyperDiv
							style={{ alignItems: "start" }}
							centered={isMobile || isSmallTablet}
							direction="column"
						>
							<b>Ella Spuriņa </b>
							<p>Scientific Assistant 3D Concrete printing laboratory</p>
						</HyperDiv>
					</HyperDiv>

					<HyperDiv width={dynamicWidth} direction="column" centered>
						<img src={KristofersCelms} />
						<Whitespace x="1" />
						<HyperDiv
							style={{ alignItems: "start" }}
							centered={isMobile || isSmallTablet}
							direction="column"
						>
							<b>Kristofers Celms </b>
							<p>RTU Design Factory</p>
						</HyperDiv>
					</HyperDiv>

					<HyperDiv width={dynamicWidth} direction="column" centered>
						<img src={Juris} />
						<Whitespace x="1" />
						<HyperDiv
							style={{ alignItems: "start" }}
							centered={isMobile || isSmallTablet}
							direction="column"
						>
							<b>Juris Kļava</b>
							<p>RTU Design Factory</p>
						</HyperDiv>
					</HyperDiv>

					<HyperDiv width={dynamicWidth} direction="column" centered>
						<img src={Elina} />
						<Whitespace x="1" />
						<HyperDiv
							style={{ alignItems: "start" }}
							centered={isMobile || isSmallTablet}
							direction="column"
						>
							<b>Elīna Miķelsone</b>
							<p>Creativity trainer</p>
							<p>RTU Design Factory</p>
						</HyperDiv>
					</HyperDiv>

					<HyperDiv width={dynamicWidth} direction="column" centered>
						<img src={Ervins} />
						<Whitespace x="1" />
						<HyperDiv
							style={{ alignItems: "start" }}
							centered={isMobile || isSmallTablet}
							direction="column"
						>
							<b>Ervīns Gorelovs</b>
							<p>Architect, designer ZJU, China </p>
						</HyperDiv>
					</HyperDiv>

					<HyperDiv width={dynamicWidth} direction="column" centered>
						<img src={Fabian} />
						<Whitespace x="1" />
						<HyperDiv
							style={{ alignItems: "start" }}
							centered={isMobile || isSmallTablet}
							direction="column"
						>
							<b>Dr. Fabian</b>
							<b>Meyer-Brötz</b>
							<p>Head of 3D Construction Printing at PERI AG </p>
						</HyperDiv>
					</HyperDiv>

					<HyperDiv width={dynamicWidth} direction="column" centered>
						<img src={Atis} />
						<Whitespace x="1" />
						<HyperDiv
							style={{ alignItems: "start" }}
							centered={isMobile || isSmallTablet}
							direction="column"
						>
							<b>Atis Ķeņģis</b>
							<p>3D artist</p>
							<p>Creative Tuesdays </p>
						</HyperDiv>
					</HyperDiv>
				</People>
				<HyperDiv width="1100px" maxWidth="95%" style={{ paddingTop: 50 }}>
					<p style={{ fontStyle: "italic", textAlign: "left" }}>
						*List may change due to availability {!isMobile && <br />}
						and scheduling options
					</p>
				</HyperDiv>
			</HyperDiv>
		</HyperDiv>
	)
}

export default Mentors

const People = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	align-items: flex-start;

	div {
		padding: 15px;
	}

	img {
		width: 80%;
	}

	p {
		font-weight: 300;
		max-width: 350px;
		text-align: left;
	}
`
