import DefaultSelect from "components/ProjectNonSpecificComponents/Elements/DefaultSelect"
import { FormInput } from "components/ProjectNonSpecificComponents/Elements/FormElements/FormInput"
import HyperDiv from "components/ProjectNonSpecificComponents/Elements/HyperDiv/HyperDiv"
import Whitespace from "components/ProjectNonSpecificComponents/Elements/Whitespace"
import useScreenType from "components/ProjectNonSpecificComponents/Hooks/useScreenType"
import { observer } from "mobx-react"
import React, { forwardRef, useMemo } from "react"
import styled from "styled-components"
import { TForm } from "../Pages/Form/Register"
import "./FormBuilder.css"
interface Props {
	fieldType: "SELECT" | "STRING" | "MULTI_PICKLIST" | "NUMBER" | "RICHTEXT" | "PHONE"
	name: keyof TForm
	customErrors?: string
	errors: any
	isRequired?: boolean
	pickListValues?: any[]
	value?: string
	charLimit?: number
	isMultilineField?: boolean
	wide?: boolean
	placeholder?: string
	description?: string
	onlyVertical?: boolean
	customLabel?: string
	hideLabel?: boolean
	/**
	 * For use with `fieldType` of 'ATTACHMENT'
	 */
	files?: File[]
	/**
	 * Used for every `fieldType` but 'ATTACHMENT'
	 */
	onChangeValue?: (value: string) => void
	/**
	 * Used for 'ATTACHMENT' `fieldType`
	 */
	onChangeFiles?: (files: File[]) => void
}

const FormBuilder = observer(
	forwardRef<any, Props>(
		(
			{
				fieldType,
				isRequired = false,
				errors,
				name,
				hideLabel = false,
				charLimit,
				pickListValues,
				value,
				customErrors,
				files,
				placeholder,
				customLabel,
				onlyVertical = true,
				wide = false,
				isMultilineField,
				description,
				onChangeValue = () => {},
				onChangeFiles,
			},
			ref
		) => {
			const { isMobile } = useScreenType()

			const InputComponent = useMemo(() => {
				switch (fieldType) {
					// case "DATE":
					// 	return <DatePickerInput onChangeDate={(date) => onChangeValue(date)} value={value} />
					case "SELECT":
						return (
							<DefaultSelect
								onChange={(selectedOption: any) => {
									onChangeValue(selectedOption.value)
								}}
								wide={wide}
								name={name}
								placeholder={placeholder}
								ref={ref}
							>
								{pickListValues?.map(({ label, value }, i) => (
									<option value={value} key={label + i}>
										{label}
									</option>
								))}
							</DefaultSelect>
						)
					case "MULTI_PICKLIST":
						// TODO: If this is going to be needed
						return <div>{"<multiSelect>"}</div>
					case "NUMBER":
						return (
							<FormInput
								type="number"
								wide={wide}
								ref={ref}
								placeholder={placeholder}
								name={name}
								value={value}
								onChange={(e) => {
									onChangeValue(e.target.value)
								}}
							/>
						)

					case "PHONE":
						return (
							<PhoneContainer
								style={{
									width: wide ? "100%" : 300,
								}}
							>
								{/* <DefaultSelect
									// style={{ width: 100 }}
									onChange={(selectedOption: any) => {
										onChangeValue(selectedOption.value)
									}}
									options={phoneOptions}
								/> */}
								<FormInput
									type="number"
									wide={wide}
									ref={ref}
									placeholder={placeholder}
									name={name}
									value={value}
									onChange={(e) => {
										onChangeValue(e.target.value)
									}}
								/>
							</PhoneContainer>
						)
					case "RICHTEXT":
						return (
							<FormInput
								charLimit={Number(charLimit) ?? undefined}
								multiline
								name={name}
								ref={ref}
								placeholder={placeholder}
								wide={wide}
								value={value}
								onChange={(e) => {
									onChangeValue(e.target.value)
								}}
							/>
						)
					case "STRING":
						return (
							<FormInput
								charLimit={Number(charLimit) ?? undefined}
								multiline={isMultilineField}
								wide={wide}
								name={name}
								placeholder={placeholder}
								ref={ref}
								value={value}
								onChange={(e) => {
									onChangeValue(e.target.value)
								}}
							/>
						)
					// case "ATTACHMENT":
					// 	return <UploadAttachmentsInput files={files} onChangeFiles={onChangeFiles} />
				}
			}, [fieldType, value, files, pickListValues])

			return (
				<>
					{(onlyVertical || isMobile) && <Whitespace x="1" />}
					{(customErrors || errors[name]) && !isMobile && !onlyVertical && (
						<Error>{customErrors ?? "Field is required"}</Error>
					)}

					<HyperDiv
						direction={onlyVertical || isMobile ? "column" : "row"}
						style={{
							fontSize: isMobile ? 14 : 18,
							fontWeight: 300,
						}}
						margin="5px 0px"
					>
						{!hideLabel && (
							<HyperDiv
								width={onlyVertical || isMobile ? "100%" : "50%"}
								hidesOverflownText
								centeredY
								className={isRequired ? "required" : ""}
								padding="0px 0px 5px 0px"
							>
								{customLabel ?? name}
							</HyperDiv>
						)}
						<Whitespace x="0.3" h={!onlyVertical || !isMobile} />
						{description && <Desc isMobile={isMobile}>{description}</Desc>}
						{(customErrors || errors[name]) && (isMobile || onlyVertical) && (
							<Error style={{ paddingBottom: 5 }}>{customErrors ?? "Field is required"}</Error>
						)}

						<HyperDiv width={onlyVertical || isMobile ? "100%" : "90%"}>{InputComponent}</HyperDiv>
					</HyperDiv>
				</>
			)
		}
	)
)

export default FormBuilder

export type TReactSelectOption = {
	value: string
	label: string
	isDisabled?: boolean
	/**
	 * In case selected option needs to provide extra information without use of array filter
	 */
	extraProp?: string
}

export const Error = styled.label`
	width: 100%;
	font-weight: 300;
	font-size: 12px;
	text-align: right;
	color: red;
`

export const PhoneContainer = styled.div`
	display: flex;
	max-width: "100%";

	input {
		border-radius: 0px 2px 2px 0px;
	}

	select {
		border-right: unset;
		border-radius: 2px 0px 0px 2px;
	}
`
export const Desc = styled.p<{ isMobile?: boolean }>`
	padding: 5px 0px;
	font-size: ${({ isMobile }) => (isMobile ? "12px" : "14px")};
	font-weight: 200;
`
