import React, { useMemo, useState } from "react"
import Airtable from "airtable"
import HyperDiv from "components/ProjectNonSpecificComponents/Elements/HyperDiv/HyperDiv"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import Button from "components/ProjectSpecificComponents/Elements/Button"
import FormBuilder from "components/ProjectSpecificComponents/Elements/FormBuilder"
import { objectLength } from "components/ProjectNonSpecificComponents/Functions/objectLength"
import { isEmail } from "components/ProjectSpecificComponents/Elements/validations"
import { useStore } from "components/ProjectSpecificComponents/Contexts/GlobalStoreContext"
import { useHistory } from "react-router"
import { ERoutes } from "App"
import HeaderLogo from "./../../Images/other/headerLogo.png"
import Whitespace from "components/ProjectNonSpecificComponents/Elements/Whitespace"
import { observer } from "mobx-react-lite"
import ListErrors from "components/ProjectNonSpecificComponents/Elements/ListErrors"

const Register = observer(() => {
	const AirtableAPI: string = process.env.REACT_APP_AIRTABLE_API_KEY ?? ""
	const AirtableBase: string = process.env.REACT_APP_BASE ?? ""
	const base = new Airtable({ apiKey: AirtableAPI }).base(AirtableBase)
	const history = useHistory()

	const addRegisteredUser = (formData: TForm) => {
		const data = [{ fields: formData }]

		base("Registration").create(data, (err: any) => {
			if (err) return
			onRegister()
			reset()
		})
	}

	const onRegister = () => history.push(ERoutes.thanks)
	const onLogoClick = () => history.push(ERoutes.landing)

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		reset,
		formState: { errors },
	} = useForm()

	const onSubmit = handleSubmit((data: any) => {
		setCustomErrors({})

		var _errors: any = {}

		if (!isEmail(data.Email)) {
			_errors.email = "Incorrect email"
		}

		if (objectLength(_errors) === 0) {
			addRegisteredUser(data)
		} else {
			setCustomErrors(_errors)
		}
	})
	const [customErrors, setCustomErrors] = useState<any>({})
	const showTeamName = watch("Team") === "Allready have a team"

	const showUniversity = useMemo(() => {
		switch (watch("Student statuss")) {
			case "Graduate":
			case "Full time":
			case "Part time":
				return true
			default:
				return false
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watch("Student statuss")])

	const store = useStore()

	return (
		<form onSubmit={onSubmit}>
			<HyperDiv
				centered
				padding="50px 0px"
				style={{ background: "black", overflowY: "scroll" }}
				height="100vh"
				alignedTop
			>
				<HyperDiv width="550px" direction="column">
					<HyperDiv
						centered
						direction="column"
						padding="30px 0px 20px 20px"
						style={{ borderRadius: 10 }}
					>
						<HyperDiv alignedRight>
							<img
								alt="header_logo"
								src={HeaderLogo}
								height="82px"
								width="255px"
								className="pointer"
								onClick={onLogoClick}
							/>
						</HyperDiv>
						<Whitespace x="1" />

						<br />
						<p style={{ fontWeight: 300, fontSize: 14 }}>
							Registration for 3D concrete printing competition. Each participant registers
							individually. Teams 1 - 5 people.
						</p>
					</HyperDiv>

					{!store.isRegistrationOpen && (
						<div style={{ width: "100%", textAlign: "center" }}>
							<br />
							<h2 style={{ textDecoration: "underline" }}>Registration closed</h2>
							<br />
							<label>See you next year!</label>
						</div>
					)}

					<HyperDiv direction="column" padding="10px 20px">
						<FormBuilder
							errors={errors}
							ref={register({ required: true })}
							wide
							isRequired
							name="Name Surname"
							fieldType="STRING"
						/>

						<FormBuilder
							errors={errors}
							ref={register({ required: true })}
							wide
							isRequired
							name="Email"
							customErrors={customErrors?.email}
							fieldType="STRING"
						/>
						<FormBuilder
							errors={errors}
							ref={register()}
							wide
							isRequired
							name="Phone"
							customLabel="Phone Nr."
							fieldType="STRING"
							customErrors={customErrors?.phone}
							description={"With country code. (Used for a shared whatsapp group)"}
						/>
						<FormBuilder
							errors={errors}
							ref={register({ required: true })}
							wide
							isRequired
							name="Your home country"
							fieldType="STRING"
						/>
						<FormBuilder
							errors={errors}
							ref={register({ required: true })}
							wide
							isRequired
							pickListValues={teamOption}
							name="Team"
							fieldType="SELECT"
							description={
								"Although not required, its highly recommended to have one Latvian student in your team to make prize transfer possible in case you win the competition. "
							}
						/>
						{showTeamName && (
							<FormBuilder
								errors={errors}
								ref={register({ required: true })}
								wide
								isRequired
								name="Your team"
								customLabel="Your team name"
								fieldType="STRING"
								description={
									"Please all participants from one team use the same team name with same capital laters, so our system can recognize your team. Feel free to use a placeholder, as you can later change the team name at the start of the competition. "
								}
							/>
						)}
						<FormBuilder
							errors={errors}
							ref={register({ required: true })}
							wide
							pickListValues={skillOption}
							isRequired
							name="Your main skill"
							fieldType="SELECT"
						/>
						<FormBuilder
							errors={errors}
							ref={register()}
							wide
							customLabel="Previous Experience"
							name="Experience"
							fieldType="RICHTEXT"
							description={
								"If you can please elaborate in one or 2 sentences or give an example of a chosen skill related project you have been a part of. "
							}
						/>
						<FormBuilder
							errors={errors}
							ref={register({ required: true })}
							wide
							pickListValues={studentStatusOption}
							isRequired
							name="Student statuss"
							fieldType="SELECT"
						/>

						{showUniversity && (
							<FormBuilder
								errors={errors}
								ref={register({ required: true })}
								wide
								isRequired
								name="University"
								fieldType="STRING"
								customLabel="Place of study"
								description={"Please also mention your faculty"}
							/>
						)}

						<FormBuilder
							errors={errors}
							ref={register()}
							wide
							name="Comments"
							fieldType="RICHTEXT"
							customLabel="Comments & Questions"
							description={
								"Feel free to get in touch with us. Let us know if and what additional skills you are looking for your team. Please mention who is the main contact person for your team."
							}
						/>

						<HyperDiv centered margin="20px 0px" direction="column">
							{objectLength(customErrors) > 0 && (
								<HyperDiv direction="column" padding="0px 40px" alignedLeft>
									Found errors in:
									<Whitespace x="0.5" />
									<ListErrors errors={customErrors} />
									<Whitespace x="1" />
								</HyperDiv>
							)}
							<Button color="red" type="submit">
								register
							</Button>
						</HyperDiv>
					</HyperDiv>
				</HyperDiv>
			</HyperDiv>
		</form>
	)
})

export type TForm = {
	"Name Surname": string
	"Your team": string
	"Your main skill": string
	"Student statuss": string
	Experience: string
	Team: string
	Email: string
	Phone: string
	"Your home country": string
	University: string
	Comments: string
}

const skillOption = [
	{ label: "", value: "" },
	{ label: "Architecture", value: "Architecture" },
	{ label: "Design", value: "Design" },
	{ label: "3D Modelling", value: "3D Modelling" },
	{ label: "Sculpting", value: "Sculpting" },
	{ label: "Project Management", value: "Project Management" },
	{ label: "3D printing", value: "3D printing" },
	{ label: "Construction & Engineering", value: "Construction & Engineering" },
	{ label: "Other", value: "Other" },
]

const studentStatusOption = [
	{ label: "", value: "" },
	{ label: "Full time", value: "Full time" },
	{ label: "Part time", value: "Part time" },
	{ label: "Graduate", value: "Graduate" },
	{ label: "Not a student", value: "Not a student" },
]

const teamOption = [
	{ label: "", value: "" },
	{ label: "Allready have a team", value: "Allready have a team" },
	{ label: "Looking for a team", value: "Looking for a team" },
	{ label: "Will participate solo", value: "Will participate solo" },
]

export default Register

export const FormLabel = styled.p`
	font-size: 18px;
	font-weight: 200;
	padding-top: 5px;
`
