import React from "react"

type Props = {
	errors: any
}

const ListErrors = ({ errors = {} }: Props) => {
	return (
		<ul>
			{Object.keys(errors).map((err) => (
				<li key={err}>{err}</li>
			))}
		</ul>
	)
}

export default ListErrors
