import React, { useState } from "react"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import nav from "./../../Images/social/nav.png"
import useScreenType from "components/ProjectNonSpecificComponents/Hooks/useScreenType"
import HyperDiv from "components/ProjectNonSpecificComponents/Elements/HyperDiv/HyperDiv"
import styled from "styled-components"

export default function NavigationDrawer() {
	const { isMobile } = useScreenType()
	const [isOpen, setIsOpen] = useState<boolean>(false)

	const toggleDrawer = () => setIsOpen((open) => !open)
	const closeDrawer = () => setIsOpen(false)
	const openDrawer = () => setIsOpen(true)

	const onNavigatorClick = () => {
		toggleDrawer()
	}

	const scrollTo = (id: string) => {
		var elmnt = document.getElementById(id)
		if (elmnt) {
			const elementRect = elmnt.getBoundingClientRect()
			const absoluteElementTop = elementRect.top + window.pageYOffset
			const middle = absoluteElementTop - window.innerHeight / 2 + 200
			window.scrollTo(0, middle)
		}
	}

	return (
		<>
			<img
				alt="navigator"
				src={nav}
				height={isMobile ? 50 : 55}
				onClick={onNavigatorClick}
				style={{ cursor: "pointer" }}
			/>

			<SwipeableDrawer
				PaperProps={{
					sx: {
						backgroundColor: "black",
						color: "white",
					},
				}}
				open={isOpen}
				anchor="right"
				onClose={closeDrawer}
				onOpen={openDrawer}
			>
				<HyperDiv
					direction="column"
					padding="10px 20px"
					width="250px"
					maxWidth="90vw"
					style={{ paddingTop: 100 }}
				>
					<NavLabel onClick={() => scrollTo("about-section")}>ABOUT</NavLabel>
					<NavLabel onClick={() => scrollTo("schedule-section")}>SCHEDULE</NavLabel>
					<NavLabel onClick={() => scrollTo("jury-section")}>JURY & MENTORS</NavLabel>
					<NavLabel onClick={() => scrollTo("prizes-section")}>PRIZES</NavLabel>
					<NavLabel onClick={() => scrollTo("criteria-section")}>EVALUATION CRITERIA</NavLabel>
					<NavLabel onClick={() => scrollTo("location-section")}>LOCATION</NavLabel>
					<NavLabel onClick={() => scrollTo("partners-section")}>PARTNERS</NavLabel>
				</HyperDiv>
			</SwipeableDrawer>
		</>
	)
}

const NavLabel = styled.label`
	padding: 10px 5px;
	font-weight: 600;
	font-size: 18;
	cursor: pointer;
	transition: opacity 0.1s ease-in-out;

	:hover {
		opacity: 0.6;
	}
`
