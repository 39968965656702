import { ERoutes } from "App"
import HyperDiv from "components/ProjectNonSpecificComponents/Elements/HyperDiv/HyperDiv"
import Whitespace from "components/ProjectNonSpecificComponents/Elements/Whitespace"
import React from "react"
import { useHistory } from "react-router"
import styled from "styled-components"
import Button from "../Elements/Button"
import scrollTo from "../Functions/scrollTo"

import LandingBG from "./../Images/other/landingHeader.png"

const Thanks = () => {
	const history = useHistory()

	const Continue = () => {
		scrollTo()
		history.push(ERoutes.landing)
	}
	return (
		<>
			<HyperDiv
				centered
				height="100vh"
				direction="column"
				style={{ position: "absolute", zIndex: 1 }}
			>
				<H2>Registration completed</H2>
				<Whitespace x="3" />
				<HyperDiv width="700px" alignedLeft direction="column" maxWidth="90%">
					<P>
						Thank you for submitting the form! If you see this message your registration is
						automatically confirmed!{" "}
					</P>

					<P>
						Please follow our facebook event page and participants group for all the relevant
						information.
					</P>
					<Whitespace x="1" />

					<P>
						Group:{" "}
						<a
							rel="noreferrer"
							target="_blank"
							style={{ color: "white", fontWeight: "bold" }}
							href="https://ej.uz/printgroup"
						>
							https://ej.uz/printgroup
						</a>
					</P>
					<P>
						Event page:{" "}
						<a
							rel="noreferrer"
							target="_blank"
							style={{ color: "white", fontWeight: "bold" }}
							href="https://ej.uz/printevent"
						>
							https://ej.uz/printevent
						</a>
					</P>
					<Whitespace x="1" />
					<P>
						Every tuesday evening we host introductionary 3D printing, modelling and VR meetups,
						feel free to watch the recordings or participate in-person.{" "}
						<a
							rel="noreferrer"
							target="_blank"
							style={{ color: "white", fontWeight: "bold" }}
							href="https://www.facebook.com/c2days"
						>
							facebook.com/c2days
						</a>
					</P>
				</HyperDiv>

				<Whitespace x="3" />
				<Button color="green" onClick={Continue}>
					Go to start
				</Button>
			</HyperDiv>

			<HyperDiv
				height="100vh"
				className="bg-image"
				style={{
					backgroundImage: `url(${LandingBG})`,
					opacity: 0.15,
					position: "absolute",
					backgroundPosition: "top",
					zIndex: 0,
				}}
			/>
		</>
	)
}

export default Thanks

const P = styled.p`
	font-weight: 300;
	text-align: left;
	margin-top: 5px;
`

const H2 = styled.h2`
	text-transform: uppercase;
`
