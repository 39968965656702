export const isEmail = (st: string) => {
	return emailRegex.test(st)
}

export const isPhone = (phone: string) => {
	return /^[\+][0-9]{10,13}$/.test(phone.replace(/\s+/g, ""))
}

const emailRegex =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
