import HyperDiv from "components/ProjectNonSpecificComponents/Elements/HyperDiv/HyperDiv"
import useScreenType from "components/ProjectNonSpecificComponents/Hooks/useScreenType"
import React from "react"
import styled from "styled-components"
import HeaderLogo from "./../../Images/other/headerLogo.png"
import yt from "./../../Images/social/yt.png"
import fb from "./../../Images/social/fb.png"
import lk from "./../../Images/social/lk.png"
import em from "./../../Images/social/em.png"
import Whitespace from "components/ProjectNonSpecificComponents/Elements/Whitespace"
import CountDownClock from "./CountDownClock"
import NavigationDrawer from "./NavigationDrawer"

enum ENavHref {
	YOUTUBE = "https://www.youtube.com/playlist?list=PLlG4G8I4lv57MU9bI54OR5Llz4axdg5Tl",
	FACEBOOK = "https://www.facebook.com/concretecompetition",
	LINKEDIN = "https://www.linkedin.com/company/concretecompetition/",
	EMAIL = "mailto: concreteprintevent@gmail.com?subject=3D Print Concrete",
}

const NavButtons = () => {
	const { isMobile } = useScreenType()

	return (
		<HyperDiv
			style={{
				position: "relative",
			}}
		>
			<CoverRouteLogo>
				<HyperDiv alignedRight width="30vw" style={{ minWidth: isMobile ? 150 : 300 }}>
					<div
						className="bg-image"
						style={{
							backgroundImage: `url(${HeaderLogo})`,
							width: isMobile ? 150 : 220,
							minWidth: isMobile ? 150 : 220,
							height: isMobile ? 50 : 66,
							borderRadius: 5,
						}}
					></div>
				</HyperDiv>

				<HyperDiv alignedRight width="30vw" style={{ minWidth: isMobile ? 150 : 300 }}>
					<CountDownClock date="2021-11-10 23:59:59" />
				</HyperDiv>
			</CoverRouteLogo>

			<MenuContainer>
				<HyperDiv
					alignedLeft
					width={isMobile ? "5vw" : "30vw"}
					style={{ minWidth: isMobile ? 45 : 65 }}
				>
					{!isMobile && (
						<>
							<a href={ENavHref.YOUTUBE} rel="noreferrer" target="_blank">
								<img src={yt} height={isMobile ? 50 : 55} alt="youtube" />
							</a>
							<Whitespace x="0.5" h />
							<a href={ENavHref.FACEBOOK} rel="noreferrer" target="_blank">
								<img src={fb} height={isMobile ? 50 : 55} alt="facebook" />
							</a>
							<Whitespace x="0.5" h />
							<a href={ENavHref.LINKEDIN} rel="noreferrer" target="_blank">
								<img src={lk} height={isMobile ? 50 : 55} alt="linkedin" />
							</a>
							<Whitespace x="0.5" h />
							<a rel="noreferrer" href={ENavHref.EMAIL} target="_blank">
								<img src={em} height={isMobile ? 50 : 55} alt="mail" />
							</a>
						</>
					)}
					<Whitespace x="0.5" h />

					<NavigationDrawer />
				</HyperDiv>
			</MenuContainer>
		</HyperDiv>
	)
}

export default NavButtons

export const CoverRouteLogo = styled.div`
	position: absolute;
	left: 0px;
	top: 50px;
	border-radius: 0px 15px 15px 0px;
	/* background-color: black; */
	padding: 15px 40px 20px 20px;
`

export const MenuContainer = styled.div`
	position: absolute;
	right: 0px;
	top: 50px;
	border-radius: 0px 15px 15px 0px;
	/* background-color: black; */
	padding: 15px 40px 20px 20px;
`
