import HyperDiv from "components/ProjectNonSpecificComponents/Elements/HyperDiv/HyperDiv"
import Whitespace from "components/ProjectNonSpecificComponents/Elements/Whitespace"
import useScreenType from "components/ProjectNonSpecificComponents/Hooks/useScreenType"
import Button from "components/ProjectSpecificComponents/Elements/Button"
import scrollTo from "components/ProjectSpecificComponents/Functions/scrollTo"
import React from "react"

const HeaderButtons = () => {
	const { isMobile } = useScreenType()

	const onAboutClick = () => {
		// scrolls to about section
		scrollTo(isMobile ? 700 : 500)
	}

	const onLecturesClick = () => {
		window.open("https://www.youtube.com/playlist?list=PLlG4G8I4lv57MU9bI54OR5Llz4axdg5Tl")
	}

	return (
		<HyperDiv
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "flex-start",
			}}
			className="btn-top-style"
			height="auto"
			direction="column"
		>
			<Whitespace x="1" />
			<Button color="red" onClick={onLecturesClick}>
				lectures
			</Button>
			<Whitespace x="1.1" />
			<Button color="green" onClick={onAboutClick}>
				about
			</Button>
		</HyperDiv>
	)
}

export default HeaderButtons
