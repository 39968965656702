import React from "react"
import { Route, Switch } from "react-router-dom"
import GlobalOverlayLoader from "./components/ProjectNonSpecificComponents/Elements/Loader/GlobalOverlayLoader"
import { observer } from "mobx-react"
import { useStore } from "./components/ProjectSpecificComponents/Contexts/GlobalStoreContext"
import HyperDiv from "components/ProjectNonSpecificComponents/Elements/HyperDiv/HyperDiv"
import Register from "components/ProjectSpecificComponents/Pages/Form/Register"
import Landing from "components/ProjectSpecificComponents/Pages/Landing/Landing"
import Thanks from "components/ProjectSpecificComponents/Pages/Thanks"

export enum ERoutes {
	landing = "/",
	register = "/register",
	thanks = "/thanks",
}

export type TUser = {}
export const App = observer(() => {
	const store = useStore()

	return (
		<>
			<GlobalOverlayLoader active={store.isLoaderOverlayOpen} />
			<HyperDiv
				style={{ overflowY: "hidden" }}
				alignedLeft
				centered
				direction="column"
				id="landing-page"
			>
				<Switch>
					<Route exact component={Register} path={ERoutes.register} />
					<Route exact component={Thanks} path={ERoutes.thanks} />
					<Route component={Landing} />
				</Switch>
			</HyperDiv>
		</>
	)
})

export default App
