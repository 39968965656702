import HyperDiv from "components/ProjectNonSpecificComponents/Elements/HyperDiv/HyperDiv"
import useScreenType from "components/ProjectNonSpecificComponents/Hooks/useScreenType"
import React from "react"
import styled from "styled-components"

const InfoP = () => {
	const { isMobile } = useScreenType()

	return (
		<HyperDiv centered id="about-section">
			<HyperDiv
				direction={isMobile ? "column" : "row"}
				spacedBetween={!isMobile}
				width="90%"
				maxWidth="1200px"
			>
				<div
					style={{
						minWidth: "50",
						paddingRight: isMobile ? "" : "2.5rem",
						paddingBottom: isMobile ? "2.5rem" : "",
					}}
				>
					<HeaderParagraph>
						3D concrete printing competition is an open activity for architecture, design and art
						students, specialists and people with skills in project management, 3D printing,
						sculpture and 3D design with an aim to increase the popularity of concrete printing and
						creating first publicly accessible 3D printed installations in Riga, located at
						University of Latvia campus at Torņakalns.
					</HeaderParagraph>
				</div>

				<div style={{ minWidth: "50%", paddingLeft: isMobile ? "" : "2.5rem" }}>
					<HeaderParagraph>
						Competition is organised in 2 stages: 4 hour online idea hackathon on and in depth 3
						month concept development incubator with mentors for selected teams.
					</HeaderParagraph>
					<HeaderParagraph>
						Final awards are assigned by professional jury after evaluating submitted prototypes. Up
						to 3 teams will win a chance to execute their idea and bring home part of 5 000 - 10 000
						EUR price pool. More information below:
					</HeaderParagraph>
				</div>
			</HyperDiv>
		</HyperDiv>
	)
}

export default InfoP

export const HeaderParagraph = styled.p``
