import "./styles/colors.css"
import "./styles/app.css"
import "./styles/random.css"

import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { GlobalStoreContextProvider } from "components/ProjectSpecificComponents/Contexts/GlobalStoreContext"
import { HashRouter as Router } from "react-router-dom"
const rootElement = document.getElementById("root")

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<GlobalStoreContextProvider>
				<App />
			</GlobalStoreContextProvider>
		</Router>
	</React.StrictMode>,
	rootElement
)
