/**
 * Concatenates any amount of strings provided into one string,
 * space separated.
 * 
 * Ignores falsy values.
 */
export default function concatWithSpaces(
  ...strings: (string | undefined | null)[]
) {
  let productSt = ''

  for (let i = 0; i < strings.length; ++i) {
    let string = strings[ i ]
    if (!string) continue
    if (i != 0) productSt += ' '
    productSt += string
  }
  
  return productSt
}
