import HyperDiv from "components/ProjectNonSpecificComponents/Elements/HyperDiv/HyperDiv"
import useScreenType from "components/ProjectNonSpecificComponents/Hooks/useScreenType"
import React from "react"
import styled from "styled-components"

import a from "./../../Images/gallery/1.png"
import b from "./../../Images/gallery/2.png"
import c from "./../../Images/gallery/3.png"
import d from "./../../Images/gallery/4.png"
import e from "./../../Images/gallery/5.png"
import f from "./../../Images/gallery/6.png"
import g from "./../../Images/gallery/7.png"

const Images = () => {
	const { isMobile, isSmallTablet } = useScreenType()
	return (
		<HyperDiv direction="row" style={{ overflowX: "scroll", paddingBottom: 45 }} width="100vw">
			<Img src={a} small={isSmallTablet || isMobile} />
			<Img src={b} small={isSmallTablet || isMobile} />
			<Img src={c} small={isSmallTablet || isMobile} />
			<Img src={d} small={isSmallTablet || isMobile} />
			<Img src={e} small={isSmallTablet || isMobile} />
			<Img src={f} small={isSmallTablet || isMobile} />
			<Img src={g} small={isSmallTablet || isMobile} />
		</HyperDiv>
	)
}

export default Images

export const Img = styled.img<{ small: boolean }>`
	border-radius: 3px;
	margin-right: 50px;
	width: ${({ small }) => (small ? "300px" : "500px")};
	height: ${({ small }) => (small ? "300px" : "500px")};
`
