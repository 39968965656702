import React, { forwardRef } from "react"
import styled from "styled-components"
import concatWithSpaces from "../Functions/concatWithSpaces"

interface Props extends React.ComponentProps<"input"> {
	/**
	 * Sets container width to 100%
	 */
	wide?: boolean
}

const Input = forwardRef<HTMLInputElement, Props>(
	({ wide = false, readOnly = false, style, className, ...props }, ref) => {
		return (
			<CustomInput
				{...props}
				className={concatWithSpaces(readOnly ? "disable-pointer-event" : "", className)}
				style={{
					width: wide ? "100%" : 300,
					maxWidth: "100%",
					opacity: readOnly ? 0.5 : 1,
					...style,
				}}
				ref={ref}
				readOnly={readOnly}
			/>
		)
	}
)

export default Input

const CustomInput = styled.input`
	height: 38px;
	padding: 0 10px;
	background-color: #161616 !important;
	background: #161616 !important;
	border-radius: 3px;
	border: 1px solid #292929;
	outline: 0ch !important;
	// Text
	color: white;
	font-family: inherit;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.6;
	text-transform: none;
	word-wrap: normal;

	// Animation
	transition-property: border-color;
`
