import HyperDiv from "components/ProjectNonSpecificComponents/Elements/HyperDiv/HyperDiv"
import useScreenType from "components/ProjectNonSpecificComponents/Hooks/useScreenType"
import React from "react"
import styled from "styled-components"

import a1 from "./../../Images/new_roller_top/1.png"
import a2 from "./../../Images/new_roller_top/2.png"
import a3 from "./../../Images/new_roller_top/3.png"
import a4 from "./../../Images/new_roller_top/4.png"
import a5 from "./../../Images/new_roller_top/5.png"
import a6 from "./../../Images/new_roller_top/6.png"
import a7 from "./../../Images/new_roller_top/7.png"
import a8 from "./../../Images/new_roller_top/8.png"
import a9 from "./../../Images/new_roller_top/9.png"
import a10 from "./../../Images/new_roller_top/10.png"
import a11 from "./../../Images/new_roller_top/11.png"
import a12 from "./../../Images/new_roller_top/12.png"
import a13 from "./../../Images/new_roller_top/13.png"
import a14 from "./../../Images/new_roller_top/14.png"
import a15 from "./../../Images/new_roller_top/15.png"
import a16 from "./../../Images/new_roller_top/16.png"

interface Props {}

const ExampleImages = (props: Props) => {
	const { isMobile, isSmallTablet } = useScreenType()
	return (
		<HyperDiv direction="row" style={{ overflowX: "scroll", paddingBottom: 45 }} width="100vw">
			<Img src={a1} small={isSmallTablet || isMobile} />
			<Img src={a2} small={isSmallTablet || isMobile} />
			<Img src={a3} small={isSmallTablet || isMobile} />
			<Img src={a4} small={isSmallTablet || isMobile} />
			<Img src={a5} small={isSmallTablet || isMobile} />
			<Img src={a6} small={isSmallTablet || isMobile} />
			<Img src={a7} small={isSmallTablet || isMobile} />
			<Img src={a8} small={isSmallTablet || isMobile} />
			<Img src={a9} small={isSmallTablet || isMobile} />
			<Img src={a10} small={isSmallTablet || isMobile} />
			<Img src={a11} small={isSmallTablet || isMobile} />
			<Img src={a12} small={isSmallTablet || isMobile} />
			<Img src={a13} small={isSmallTablet || isMobile} />
			<Img src={a14} small={isSmallTablet || isMobile} />
			<Img src={a15} small={isSmallTablet || isMobile} />
			<Img src={a16} small={isSmallTablet || isMobile} />
		</HyperDiv>
	)
}

export default ExampleImages

export const Img = styled.img<{ small: boolean }>`
	border-radius: 3px;
	margin-right: 50px;
	width: ${({ small }) => (small ? "300px" : "500px")};
	height: ${({ small }) => (small ? "300px" : "500px")};
`
