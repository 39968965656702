import React, { forwardRef } from "react"
import styled from "styled-components"

interface Props extends React.ComponentProps<"textarea"> {
	/**
	 * Sets container width to 100%
	 */
	wide?: boolean
}

const MultilineInput = forwardRef<HTMLTextAreaElement, Props>(
	({ wide = false, readOnly = false, style, ...props }, ref) => {
		return (
			<CustomTextArea
				{...props}
				style={{
					width: wide ? "100%" : 300,
					maxWidth: "100%",
					opacity: readOnly ? 0.5 : 1,
					...style,
				}}
				ref={ref}
			/>
		)
	}
)

export default MultilineInput

const CustomTextArea = styled.textarea`
	min-height: 99px;
	max-height: 60vh;
	padding: 10px;
	resize: vertical;

	background-color: #161616 !important;
	background: #161616 !important;
	border-radius: 3px;
	border: 1px solid #292929;
	outline: 0ch !important;
	// Text
	color: white;
	font-family: inherit;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.6;
	text-transform: none;
	word-wrap: break-word;

	// Animation
	transition: 0.2s var(--default-slide-transition);
	transition-property: border-color;
`
